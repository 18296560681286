import axios from 'axios';


const API_URL = 'https://edusensei-api-pyhu2xy4sq-et.a.run.app'
// Courses
export const getCourses = async () => {
  try {
    const response = await axios.get(`${API_URL}/courses`);
    return response.data;
  } catch (error) {
    console.error('Error fetching courses', error);
    throw error;
  }
};

export const fetchPayments = async () => {
  try {
    const response = await axios.get(`${API_URL}/payments`);
    return response.data; // Mengembalikan data pembayaran dari API
  } catch (error) {
    console.error('Error fetching payments:', error);
    throw error;
  }
};

export const getCourseById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/courses/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching course with id ${id}`, error);
    throw error;
  }
};

// Materials
export const getMaterials = async () => {
  try {
    const response = await axios.get(`${API_URL}/materials`);
    return response.data;
  } catch (error) {
    console.error('Error fetching materials', error);
    throw error;
  }
};

export const getMaterialById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/materials/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching material with id ${id}`, error);
    throw error;
  }
};

export const getMaterialByCourse = async (courseId) => {
  try {
    const response = await axios.get(`${API_URL}/materials/course/${courseId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching materials for course with id ${courseId}`, error);
    throw error;
  }
};

// Subscriptions
export const getSubscriptions = async () => {
  try {
    const response = await axios.get(`${API_URL}/subscriptions`);
    return response.data;
  } catch (error) {
    console.error('Error fetching subscriptions', error);
    throw error;
  }
};

export const getSubscriptionById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/subscriptions/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching subscription with id ${id}`, error);
    throw error;
  }
};

export const getSubscriptionByUserId = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/subscriptions/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching subscriptions for user with id ${userId}`, error);
    throw error;
  }
};

// Users
export const createUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/users`, userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user', error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/users`);
    return response.data;
  } catch (error) {
    console.error('Error fetching users', error);
    throw error;
  }
};

export const getUserById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/users/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching user with id ${id}`, error);
    throw error;
  }
};

export const updateUser = async (uid, data) => {
  try {
    await axios.put(`${API_URL}/users/${uid}`, data);
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await axios.delete(`${API_URL}/users/${id}`);
  } catch (error) {
    console.error(`Error deleting user with id ${id}`, error);
    throw error;
  }
};


export const checkSubscription = async (userId, courseId) => {
  try {
    const response = await axios.get(`${API_URL}/subscriptions/check/${userId}/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error checking subscription:', error);
    throw error;
  }
};
export const getPaidSubscriptionsByUserId = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/subscriptions/paid/${userId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching paid subscriptions for user with id ${userId}`, error);
    throw error;
  }
};
export const createSubscription = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/subscriptions`, data);
    return response.data;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};
export const getVideoById = async (videoId) => {
  try {
    const response = await fetch(`${API_URL}/videos/${videoId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch video');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching video:', error);
    throw error; // Buang error agar bisa ditangani oleh komponen yang memanggilnya
  }
};

export const getQuizByCourseId = async (courseId) => {
  try {
    const response = await axios.get(`${API_URL}/quizzes/course/${courseId}`);
    console.log('API Response:', response.data); // Log API response
    return response.data;
  } catch (error) {
    console.error('Error fetching quiz by course ID', error);
    throw error;
  }
};


export const getQuizById = async (Id) => {
  try {
    const response = await axios.get(`${API_URL}/quizzes/${Id}`);
    console.log('API Response:', response.data); // Log API response
    return response.data;
  } catch (error) {
    console.error('Error fetching quiz by course ID', error);
    throw error;
  }
};

export const getQuestionByQuizId = async (quizId) => {
  try {
    const response = await axios.get(`${API_URL}/questions/quiz/${quizId}`);
    console.log('API Response:', response.data); // Log API response
    return response.data;
  } catch (error) {
    console.error('Error fetching quiz by course ID', error);
    throw error;
  }
};

export const getNextMaterial = async (courseId, order) => {
  try {
    const response = await fetch(`/api/materials?courseId=${courseId}&order=${order}`);
    const data = await response.json();
    return data.length > 0 ? data[0] : null;
  } catch (error) {
    console.error('Error fetching next material', error);
    throw error;
  }
};

export const getCourseByName = async (name) => {
  try {
    const response = await axios.get(`${API_URL}/courses/name/${name}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching course by name:', error);
    throw error;
  }
};

// Fungsi untuk mendapatkan kursus berdasarkan genre
export const getCourseByGenre = async (genre) => {
  try {
    const response = await axios.get(`${API_URL}/courses/genre/${genre}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching course by genre:', error);
    throw error;
  }
};

// Fungsi untuk mendapatkan semua genre
export const getAllGenres = async () => {
  try {
    const response = await axios.get(`${API_URL}/courses/search/genres`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all genres:', error);
    throw error;
  }
};
